import React from 'react';
import Img from 'gatsby-image';
import classnames from 'classnames';
import Link, { ArrowLink } from 'components/Shared/Link/Link';
import './InfoCardSmall.scss';

const InfoCardSmall = ({ className, data }) => (
  <div className={classnames('aaa-infocard-small', className, {
    'aaa-infocard-link': data.link
  })}>
    <div className="aaa-infocard-small__image-container">
      { data.link ? (
        <Link to={data.link.url}>
          <Img
            className="aaa-infocard-small__image"
            fluid={data.image.childImageSharp.fluid}
          />
        </Link>
      ) : (
        <Img
          className="aaa-infocard-small__image"
          fluid={data.image.childImageSharp.fluid}
        />
      )}
    </div>
    <div className="aaa-infocard-small__content-container">
      <div className="aaa-infocard-small__title">
      { data.link ? (
        <Link to={data.link.url}>
          {data.title}<br/>{data.subtitle}
        </Link>
       ) : data.title }
      </div>
      { data.description && 
        <div 
          className="aaa-infocard-small__description" 
          dangerouslySetInnerHTML={{__html: data.description}}
        />
      }
      {data.note && <div className="aaa-infocard-small__note">{data.note}</div>}
      {data.link && (
        <div className="aaa-infocard-small__link-container">
          <ArrowLink className="aaa-infocard-small__link" to={data.link.url}>
            {data.link.text}
          </ArrowLink>
        </div>
      )}
    </div>
  </div>
);

export default InfoCardSmall;
