import React from 'react';
import classnames from 'classnames';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import InfoCardSmall from './InfoCardSmall';
import {
  LinkArrowButtonSecondary,
  LinkArrowButtonPrimary,
} from 'components/Shared/Button/Button';
import Icon from 'components/Shared/Icon/Icon';
import './InfoBlock.scss';

export const IconsBlock = ({ data, nobg }) => (
  <div className={`aaa-icons-block ${nobg ? 'transparent-bg' : ''}`}>
    <ContentBox>
      <div className="aaa-icons-block__container">
        <div className="row justify-content-center">
          {data.icons.map((item, i) => (
            <div key={i} className="col-6 col-md-4 col-lg d-flex flex-column">
              <div className="aaa-icons-block__icon-container">
                <Icon name={item.icon} />
              </div>
              <div className="aaa-icons-block__icon-text">{item.text}</div>
            </div>
          ))}
        </div>
      </div>
    </ContentBox>
  </div>
);

export const InfoContainer = ({ data, children, bg }) => (
  <div className={classnames('aaa-info-container', {'light-grey': bg === 'light-grey'})}>
    {children}
    {data && data.disclaimers && (
      <ContentBox>
        <div className='aaa-info-container__disclaimers'>
          {data.disclaimers.map( (disclaimer, i) => (
            <div 
              className='aaa-info-container__disclaimer'
              dangerouslySetInnerHTML={{ __html: disclaimer }}
              key={i}
            />
          ))}
        </div>
      </ContentBox>
    )}
  </div>
);

const InfoBlock = ({ data, nobg }) => (
  <div className={`aaa-infoblock ${nobg ? 'transparent-bg' : ''}`}>
    <ContentBox>
      <h2 className="aaa-infoblock__title">{data.title}</h2>
      {data.subtitle && (
        <div className="aaa-infoblock__subtitle">{data.subtitle}</div>
      )}
      <div className="aaa-infoblock__cards-container">
        <div className="row justify-content-center">
          {data.infoCards.map((item, i) => (
            <div
              key={i}
              className={classnames(
                "col-12 aaa-infoblock__card", 
                data.type === "2-col" ? "col-lg-6" : "col-lg-4" 
              )}
            >
              <InfoCardSmall data={item} />
            </div>
          ))}
        </div>
      </div>
    </ContentBox>
    {data.icons && <IconsBlock data={data} nobg={nobg} />}

    {(data.primaryButton || data.secondaryButton) && (
      <ContentBox>
        <div className="aaa-infoblock__buttons-container">
          {data.secondaryButton && (
            <LinkArrowButtonSecondary to={data.secondaryButton.url}>
              {data.secondaryButton.text}
            </LinkArrowButtonSecondary>
          )}
          {data.primaryButton && (
            <LinkArrowButtonPrimary to={data.primaryButton.url}>
              {data.primaryButton.text}
            </LinkArrowButtonPrimary>
          )}
        </div>
      </ContentBox>
    )}
  </div>
);

export default InfoBlock;
