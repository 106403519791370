import React from 'react';
import classnames from 'classnames';

import App from 'static/images/icons/icon-app.inline.svg';
import BatteryLife from 'static/images/icons/icon-battery-life.inline.svg';
import Connectivity from 'static/images/icons/icon-connectivity.inline.svg';
import ConnectivityHH from 'static/images/icons/icon-connectivity-hh.inline.svg';
import Customized from 'static/images/icons/icon-customized.inline.svg';
import Dispatch246 from 'static/images/icons/icon-dispatch-246.inline.svg';
import EnergyManagement from 'static/images/icons/icon-energy-management.inline.svg';
import FireSmoke from 'static/images/icons/icon-fire-smoke.inline.svg';
import FOV113 from 'static/images/icons/icon-fov-113.inline.svg';
import FOV117 from 'static/images/icons/icon-fov-117.inline.svg';
import FOV180 from 'static/images/icons/icon-fov-180.inline.svg';
import FullHD from 'static/images/icons/icon-full-hd.inline.svg';
import FullHD7201080 from 'static/images/icons/icon-full-hd-720-1080.inline.svg';
import HDR from 'static/images/icons/icon-HDR.inline.svg';
import IntrusionDetection from 'static/images/icons/icon-intrusion-detection.inline.svg';
import Keyless from 'static/images/icons/icon-keyless.inline.svg';
import LiveVideo from 'static/images/icons/icon-live-video.inline.svg';
import LowMonthlyRates from 'static/images/icons/icon-low-monthly-rates.inline.svg';
import NightVision from 'static/images/icons/icon-night-vision.inline.svg';
import NoContracts from 'static/images/icons/icon-no-contracts.inline.svg';
import Notifications from 'static/images/icons/icon-notifications.inline.svg';
import Resolution from 'static/images/icons/icon-resolution.inline.svg';
import SecureCommunications from 'static/images/icons/icon-secure-communications.inline.svg';
import TwoWayAudio from 'static/images/icons/icon-2way-audio.inline.svg';
import VideoRecorded from 'static/images/icons/icon-video-recorded.inline.svg';
import WDR from 'static/images/icons/icon-wdr.inline.svg';
import Zoom6mp from 'static/images/icons/icon-zoom-6mp.inline.svg';

import './Icon.scss';

const Icons = {
  App,
  BatteryLife,
  Connectivity,
  ConnectivityHH,
  Customized,
  Dispatch246,
  EnergyManagement,
  FireSmoke,
  FOV113,
  FOV117,
  FOV180,
  FullHD,
  FullHD7201080,
  HDR,
  IntrusionDetection,
  Keyless,
  LiveVideo,
  LowMonthlyRates,
  NightVision,
  NoContracts,
  Notifications,
  Resolution,
  SecureCommunications,
  TwoWayAudio,
  VideoRecorded,
  WDR,
  Zoom6mp
}

const Icon = ({ name, className }) => {
  const I = Icons[name] || App; 
  return <div className={classnames('aaa-icon', className)}><I/></div>;
}

export default Icon;
